import React, { Component, useState } from "react"
import { connect } from "react-redux"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link, navigate } from "gatsby"
import { login } from "../store/actions/authActions"
import Slider2 from "../components/slider2"

const Login = props => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [showError, setShowError] = useState(true)
  const { isLoading, errors } = props
  const handleLogin = e => {
    e.preventDefault()
    props
      .login({
        email: email,
        password: password,
      })
      .then(response => {
        if (response.success === false) {
          console.log("Login error")
        } else {
          navigate("/cart")
        }
      })
      .catch(() => {
        console.log("Login error")
      })
  }
  const pageName = "CONNEXION"
  return (
    <Layout>
      <SEO title={pageName} />
      <Slider2 title={pageName} />
      <div className="flex flex-wrap py-6">
        <div className="container mx-auto">
          <form
            onSubmit={handleLogin}
            className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
          >
            <div className="text-center text-2xl py-5">CONNEXION</div>
            {errors && showError && (
              <div
                className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative my-2"
                role="alert"
              >
                <strong className="font-bold">Oups !</strong>&nbsp;
                <span className="block sm:inline">{errors}</span>
                <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                  <svg
                    className="fill-current h-6 w-6 text-red-500"
                    role="button"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    onClick={() => setShowError(!showError)}
                  >
                    <title>Close</title>
                    <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                  </svg>
                </span>
              </div>
            )}
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="email-address"
              >
                Adresse email
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="email-address"
                type="email"
                name="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </div>
            <div className="mb-6">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="password"
              >
                Mot de passe
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                id="password"
                type="password"
                name="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
            </div>
            <div className="flex items-center justify-between">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
              >
                CONNEXION
              </button>
              {/* <Link
                className="inline-block align-baseline text-sm text-blue-500 hover:text-blue-800"
                to="/password-reset"
              >
                Mot de passe oublié ?
              </Link> */}
            </div>
            <div className="text-center text-xs pt-6">
              <Link to="/register">
                Pas encore de compte ?&nbsp;
                <span className="text-green-600 hover:text-red-800 font-bold">
                  Inscrivez-vous
                </span>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    isLoading: state.auth.isLoading,
    errors: state.auth.errors,
  }
}

const mapStateToDispatch = {
  login,
}

export default connect(mapStateToProps, mapStateToDispatch)(Login)
